import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Foundational`}</h2>
    <h3>{`Foreground`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-functional-tables--foreground'
    }]} height="760" mdxType="StorybookEmbed" />
    <h3>{`Background`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-functional-tables--background'
    }]} height="1280" mdxType="StorybookEmbed" />
    <h3>{`Border`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-functional-tables--border'
    }]} height="1200" mdxType="StorybookEmbed" />
    <h3>{`Shadow`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-functional-tables--shadow'
    }]} height="460" mdxType="StorybookEmbed" />
    <h2>{`Pattern`}</h2>
    <h3>{`Button`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-patterns--button'
    }]} height="640" mdxType="StorybookEmbed" />
    <h3>{`Control`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-patterns--control'
    }]} height="640" mdxType="StorybookEmbed" />
    <h3>{`Data visualization`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-datavis--highcharts-accent-colors'
    }]} height="640" mdxType="StorybookEmbed" />
    <h3>{`Focus`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-patterns--focus'
    }]} height="220" mdxType="StorybookEmbed" />
    <h3>{`Overlay`}</h3>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook/" stories={[{
      id: 'color-patterns--overlay'
    }]} height="220" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      